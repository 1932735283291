<template>
    <b-modal ref="modal2" id="modal2" title="Plan Details" centered size="extra-small" hide-footer>
        <div class="col-12 stretch-card pe-4">
            <div v-bind:class="{
                    'card bg-gradient-danger block inner': subscription_plan.background === 'Red',
                    'card bg-gradient-info block inner': subscription_plan.background === 'Blue',
                    'card bg-gradient-success block inner': subscription_plan.background === 'Green',
                    'card bg-gradient-secondary block inner': subscription_plan.background === 'Gray'  // Optional for additional background
                } ">
                <div class="card-body">
                    <div class="text-center">
                        <span class="text-white">Subscription Reference Id: {{subscription_plan.subscription_id}}</span>
                    </div>
                    <h3 class="text-center text-white mt-1">Plan Name: {{subscription_plan.plan_name}}</h3>
                    <h6 class="text-center text-white mt-1">Purchased Date: {{subscription_plan.purchased_date}}</h6>
                    <h6 class="text-center text-white mt-1">Purchased Price: ${{subscription_plan.purchased_price}}</h6>
                    <h4 class="text-center text-danger mt-3">Due In: {{subscription_plan.plan_expire_in}}</h4>

                    <!-- /CONTENT -->
                    <!-- FEATURES -->
                    <ul class="list-star text-white">
                        <li v-for="feature in subscription_plan.features" :key="feature.feature_id">
                            {{feature.feature_name}} {{feature.feature_desc}}
                        </li>
                    </ul>
                    <ul v-if="subscription_plan.support_in_time" class="list-star text-white">
                        <li>Support in {{subscription_plan.support_in_time}} Days</li>
                    </ul>
                    <!-- /FEATURES -->

                    <!-- PT-FOOTER -->
                    <div v-if="subscription_plan.is_auto_renewal" class="text-center mt-2">
                        <span class="text-white">Auto Renewal</span>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
import { mapActions} from "vuex";
import moment from "moment";

export default {
    components: {
    },
    data() {
        return {
          moment,
          subscription_plan: null
        }
    },
    mounted(){
    },
    methods: {
        ...mapActions("hospital",[]),
        show() {
            this.$refs.modal2.show();
        },

        hide() {
            this.$refs.modal2.hide();
        },

        clearData() {
            this.subscription_plan = null
        },

        receiveData(subscription_plan) {
            this.subscription_plan = subscription_plan
        },
    }
}
</script>

<template>
<div>
<nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="page-header">
        <h4 class="page-title">Admin Details</h4>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Admin Details</h4>
              <form class="form-sample">
                <div class="row">
                   <input type="file" style="display: none" accept="image/*" ref="image" v-on:change="handleFileInput"/>
                  <div class="col-md-3" @click="openImagePicker()">
                    <div class="imagec">
                          <img src="../../assets/dummy-profile.jpg" loading="lazy" v-if="profile_pic == ''" class="img-fluid w-100" >
                          <img v-else :src="profilePic()" alt="profile-pic" class="img-fluid w-100"
                            @error="handleImageError" loading="lazy">
                          <div class="img-top-right" v-if="profile_pic">
                            <button type="button" @click="deleteProfilePic()" class="btn btn-outline-danger btn-rounded btn-icon"><i class="mdi mdi-delete"></i></button>
                          </div>
                          <div class="img-centered" v-if="img_progress">
                            <b-spinner label=""></b-spinner>
                          </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">User Name</label>
                        <input type="text" class="form-control" v-model="$v.typeform.doctor_name.$model" :class="{ 'is-invalid':  $v.typeform.doctor_name.$error  }" name="doctor_name" placeholder="Doctor Name"/>
                        <div v-if="$v.typeform.doctor_name.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.doctor_name.required">Please enter user name</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Email Address</label>
                        <input type="text" class="form-control" v-model="$v.typeform.doctor_email.$model" name="doctor_email_address" placeholder="Email Address" :class="{ 'is-invalid':  $v.typeform.doctor_email.$error  }" disabled/>

                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Mobile Number</label>
                        <input type="text" class="form-control" v-model="$v.typeform.doctor_mobile_number.$model" name="doctor_mobile_number" placeholder="Doctor Mobile" :class="{ 'is-invalid':  $v.typeform.doctor_mobile_number.$error  }" disabled/>

                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Emergency Mobile Number</label>
                        <input type="text" class="form-control"
                            v-model="$v.typeform.doctor_emergency_contact_number.$model"
                            name="doctor_emergency_mobile_number"
                            placeholder="Emergency Mobile Number"
                            :class="{ 'is-invalid':  $v.typeform.doctor_emergency_contact_number.$error  }"/>
                        <div v-if="$v.typeform.doctor_emergency_contact_number.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.doctor_emergency_contact_number.required">Please enter emergency mobile number</span>
                        </div>
                      </div>
                    </div>
                  </div>
                    <div class="col-md-4">
                      <label class="col-form-label">Department</label>
                      <div class="col-sm-12">
                        <div class="col-sm-10">
                          <multiselect v-model="department_selected" :options="departments"
                                        :multiple="true" :close-on-select="false" :clear-on-select="false"
                                        :preserve-search="true" placeholder="Department" label="department_name"
                                        track-by="department_name" :preselect-first="true" :showLabels="false"></multiselect>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">ID Card Number</label>
                        <input type="text" class="form-control" v-model="$v.typeform.identification.$model" name="identification" placeholder="Identification" :class="{ 'is-invalid':  $v.typeform.identification.$error  }"/>
                        <div v-if="$v.typeform.identification.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.identification.required">Please enter id card number</span>
                        </div>
                      </div>
                    </div>
                 </div>
                  <div class="col-md-3">
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <label class="col-form-label">Gender</label>
                          <multiselect v-model="$v.typeform.gender.$model" :options="gender_list"
                                       :multiple="false" :close-on-select="true" :clear-on-select="false"
                                       :preserve-search="true" placeholder="Gender"
                                       :preselect-first="true" :showLabels="false"></multiselect>
                          <div v-if="$v.typeform.gender.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform.gender.required">Please select gender</span>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Married?</label>
                        <multiselect v-model="$v.typeform.marital_status.$model" :options="option_yes_no"
                                   :multiple="false" :close-on-select="true" :clear-on-select="false"
                                   :preserve-search="true" placeholder="Marital Status"
                                   :preselect-first="true" :showLabels="false"></multiselect>
                        <div v-if="$v.typeform.marital_status.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.marital_status.required">Please select marital status</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Birth Date</label>
        			    <div class="form-control">
        			    <DatePicker class="date-picker"
        			                v-model="$v.typeform.birth_date.$model"
        			                format="DD-MM-YYYY"
        			                placeholder="Date of Birth"
        			                :disabled-date="disableDatesAfterMax"/>

                      </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Blood Group</label>
                        <multiselect v-model="$v.typeform.blood_group.$model" :options="blood_group_list"
                                       :multiple="false" :close-on-select="true" :clear-on-select="false"
                                       :preserve-search="true" placeholder="Blood Group"
                                       :preselect-first="true" :showLabels="false"></multiselect>
                        <div v-if="$v.typeform.blood_group.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.blood_group.required">Please select blood group</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Address</label>
                        <textarea rows="5" type="text"
                                    class="form-control"
                                    v-model="$v.typeform.doctor_address.$model"
                                    name="address" placeholder="Address"
                                    :class="{ 'is-invalid':  $v.typeform.doctor_address.$error }"/>
                        <div v-if="$v.typeform.doctor_address.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.doctor_address.required">Please enter address</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">City</label>
                        <input type="text" class="form-control"
                                v-model="$v.typeform.doctor_city.$model"
                                name="doctor_city" placeholder="City"
                                :class="{ 'is-invalid':  $v.typeform.doctor_city.$error  }"/>
                        <div v-if="$v.typeform.doctor_city.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.doctor_city.required">Please enter city</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Postal Code</label>
                        <input type="number" class="form-control"
                                v-model="$v.typeform.doctor_postal_code.$model"
                                name="doctor_postal_code" placeholder="Postal Code"
                                :class="{ 'is-invalid':  $v.typeform.doctor_postal_code.$error  }"/>
                        <div v-if="$v.typeform.doctor_postal_code.$error" class="invalid-feedback">
                          <span v-if="!$v.typeform.doctor_postal_code.required">Please enter postal code</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3" >
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Username</label>
                        <input type="text" class="form-control"
                                v-model="$v.typeform.username.$model"
                                name="username" placeholder="Username"
                                :class="{ 'is-invalid':  $v.typeform.username.$error  }" disabled/>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                      <div class="form-group row">
                        <div class="col-sm-12">
                          <label class="col-form-label">Description(Optional)</label>
                          <textarea rows="2" type="text"
                                      class="form-control"
                                      v-model="$v.typeform.desc.$model"
                                      name="desc" placeholder="Description..."
                                      :class="{ 'is-invalid':  $v.typeform.desc.$error }"/>
                          <div class="mt-1">
                            <small class="text-muted">This description will display on landing page below doctor name</small>
                          </div>
                        </div>
                      </div>
                  </div>

                </div>
              </form>
                <br/><br/>
               <div class="col-md-12">
                   <b-button v-if="is_btn_spinner" variant="primary" disabled>
                       <b-spinner small type="grow"></b-spinner>Loading...
                   </b-button>
                   <button v-else type="button" @click="editSubmitForm()" class="btn btn-gradient-primary me-2">Submit</button>
               </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-5" v-if="is_client_master_user">
        <br/><br/>
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Business Details</h4>
              <form class="form-sample">
                <div class="row">

                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Business Name</label>
                        <input type="text" class="form-control" v-if="business_name" v-model="$v.typeform_business.business_name.$model" :class="{ 'is-invalid':  $v.typeform_business.business_name.$error  }" placeholder="Business Name" disabled/>
                        <input type="text" class="form-control" v-else v-model="$v.typeform_business.business_name.$model" :class="{ 'is-invalid':  $v.typeform_business.business_name.$error  }" placeholder="Business Name"/>
                          <div v-if="$v.typeform_business.business_name.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.business_name.required">Please enter business name</span>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Owner Name</label>
                        <input type="text" class="form-control" v-model="$v.typeform_business.name.$model" :class="{ 'is-invalid':  $v.typeform_business.name.$error  }" placeholder="Owner Name"/>
                          <div v-if="$v.typeform_business.name.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.name.required">Please enter name</span>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Email Address</label>
                        <input type="text" class="form-control" v-model="$v.typeform_business.email.$model" :class="{ 'is-invalid':  $v.typeform_business.email.$error  }" placeholder="Email Address" disabled/>
                          <div v-if="$v.typeform_business.email.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.email.required">Please enter email address</span>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Mobile Number</label>
                        <input type="text" class="form-control" v-model="$v.typeform_business.mobile.$model" :class="{ 'is-invalid':  $v.typeform_business.mobile.$error  }" placeholder="Mobile Number" disabled/>
                          <div v-if="$v.typeform_business.mobile.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.mobile.required">Please enter mobile number</span>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                      <div class="col-sm-12">
                        <label class="col-form-label">Multi Specialist?</label>
                        <multiselect v-model="$v.typeform_business.multi_specialist.$model" :options="option_yes_no"
                                     :multiple="false" :close-on-select="true" :clear-on-select="false"
                                     :preserve-search="true" placeholder="Multi Specialist?"
                                     :preselect-first="true" :showLabels="false"></multiselect>
                      <div v-if="$v.typeform_business.multi_specialist.$error" class="invalid-feedback">
                        <span v-if="!$v.typeform_business.multi_specialist.required">Please select Multi Specialist</span>
                      </div>
                      </div>
                  </div>

                  <div class="col-md-3">
                      <div class="col-sm-12">
                        <label class="col-form-label">Are You Doctor?</label>
                        <multiselect v-model="$v.typeform_business.single_doctor.$model" :options="option_yes_no"
                                     :multiple="false" :close-on-select="true" :clear-on-select="false"
                                     :preserve-search="true" placeholder="Are You Doctor?"
                                     :preselect-first="true" :showLabels="false"></multiselect>
                      <div v-if="$v.typeform_business.single_doctor.$error" class="invalid-feedback">
                        <span v-if="!$v.typeform_business.single_doctor.required">Please select are you doctor</span>
                      </div>
                      </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">No. of Departments(Optional)</label>
                        <input type="text" class="form-control" v-model="$v.typeform_business.no_of_department.$model" :class="{ 'is-invalid':  $v.typeform_business.no_of_department.$error  }" placeholder="No. of Department"/>
                          <div v-if="$v.typeform_business.no_of_department.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.no_of_department.required">Please enter department count</span>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">No. of Staff(Optional)</label>
                        <input type="text" class="form-control" v-model="$v.typeform_business.no_of_staff.$model" :class="{ 'is-invalid':  $v.typeform_business.no_of_staff.$error  }" placeholder="No. of Staff"/>
                          <div v-if="$v.typeform_business.no_of_staff.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.no_of_staff.required">Please enter staff count</span>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Hospital Address</label>
                        <textarea rows="5" class="form-control" v-model="$v.typeform_business.address.$model" :class="{ 'is-invalid':  $v.typeform_business.address.$error  }" placeholder="Hospital Address"/>
                          <div v-if="$v.typeform_business.address.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.address.required">Please enter address</span>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">City</label>
                        <input type="text" class="form-control" v-model="$v.typeform_business.city.$model" :class="{ 'is-invalid':  $v.typeform_business.city.$error  }" placeholder="City"/>
                          <div v-if="$v.typeform_business.city.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.city.required">Please enter city</span>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">State</label>
                        <input type="text" class="form-control" v-model="$v.typeform_business.state.$model" :class="{ 'is-invalid':  $v.typeform_business.state.$error  }" placeholder="State"/>
                          <div v-if="$v.typeform_business.state.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.state.required">Please enter state</span>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Postal Code</label>
                        <input type="text" class="form-control" v-model="$v.typeform_business.postal_code.$model" :class="{ 'is-invalid':  $v.typeform_business.postal_code.$error  }" placeholder="Postal Code"/>
                          <div v-if="$v.typeform_business.postal_code.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.postal_code.required">Please enter postal code</span>
                          </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="form-group row">
                      <div class="col-sm-12">
                        <label class="col-form-label">Country</label>
                        <input type="text" class="form-control" v-model="$v.typeform_business.country.$model" :class="{ 'is-invalid':  $v.typeform_business.country.$error  }" placeholder="Country"/>
                          <div v-if="$v.typeform_business.country.$error" class="invalid-feedback">
                            <span v-if="!$v.typeform_business.country.required">Please enter country</span>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <b-button v-if="is_btn_spinner_business" variant="primary" disabled>
                 <b-spinner small type="grow"></b-spinner>Loading...
              </b-button>
              <button v-else type="button" @click="editBusinessDetails()" class="btn btn-gradient-primary me-2">Submit</button>
            </div>
          </div>
        </div>
      </div>

    <div class="row mt-5"  v-if="login_history">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <h4 class="card-title">Login History</h4>
                  <div class="table-responsive">
                      <table class="table table-hover">
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>Device</th>
                              <th>Time Zone</th>
                              <th>Location</th>
                              <th>Logged In Time</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(history,i) in login_history" :key="i">
                              <td>{{i + 1}}</td>
                              <td>{{history.browser_details}}</td>
                              <td>{{history.system_time_zone}}</td>
                              <td>{{history.location}}</td>
                              <td>{{moment(history.login_time)}}</td>
                              <td v-if="storedDeviceId == history.device_id" class="text-primary text-center">Current Device</td>
                              <td v-else><button type="button" class="btn btn-outline-primary btn-fw" @click="logout(history.device_id)">Logout</button></td>
                            </tr>
                          </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

      <div class="row mt-5"  v-if="is_client_master_user">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Subscription Transactions Details</h4>
              <div class="table-responsive">
                  <table class="table table-hover ">
                      <thead>
                        <tr>
                          <th>Order Id</th>
                          <th>Subscription Start Date</th>
                          <th>Expire</th>
                          <th>Payment Id</th>
                          <th>Amount</th>
                          <th>Payment Status</th>
                          <th>Receipt</th>
                          <th>Created At</th>
                          <th>Updated At</th>
                          <th>Notes</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(transaction,i) in transactions" :key="i">
                          <td @click="openSubscriptionPlanModel(transaction.subscription_plan)" v-if="(transaction.payment_status == 'Completed' || transaction.payment_status == 'Refunded') && transaction.subscription_plan && !transaction.subscription_plan.is_subscription_expired"><span class="logged-in">●</span> {{transaction.order_id}}</td>
                          <td @click="openSubscriptionPlanModel(transaction.subscription_plan)" v-else>{{transaction.order_id}}</td>

                          <td @click="openSubscriptionPlanModel(transaction.subscription_plan)" v-if="transaction.subscription_plan">{{transaction.subscription_plan.purchased_date}}</td>
                          <td @click="openSubscriptionPlanModel(transaction.subscription_plan)" v-else>-</td>

                          <td @click="openSubscriptionPlanModel(transaction.subscription_plan)"  v-if="transaction.subscription_plan">{{transaction.subscription_plan.plan_expire_in}}</td>
                          <td @click="openSubscriptionPlanModel(transaction.subscription_plan)" v-else>-</td>

                          <td @click="openSubscriptionPlanModel(transaction.subscription_plan)" >{{transaction.payment_id}}</td>
                          <td @click="openSubscriptionPlanModel(transaction.subscription_plan)" >{{transaction.amount}}</td>
                          <td @click="openSubscriptionPlanModel(transaction.subscription_plan)">
                          <label v-bind:class="{
                                'badge badge-success': transaction.payment_status === 'Completed',
                                'badge badge-warning': transaction.payment_status === 'Pending' || transaction.payment_status === 'Processing',
                                'badge badge-info': transaction.payment_status === 'Refunded',
                                'badge badge-danger': transaction.payment_status === 'Hold' || transaction.payment_status === 'Failed',
                                }">
                          {{transaction.payment_status_display}}
                          </label>
                          </td>

                          <td v-if="transaction.payment_status == 'Completed' || transaction.payment_status == 'Refunded'"><a href="" @click="downloadPaymentTransactionInvoice($event, transaction.order_id)">Download - {{transaction.receipt_id}}</a></td>
                          <td v-else><a href="" @click="verifyTransactionPayment($event, transaction.order_id)">Verify Payment</a></td>

                          <td @click="openSubscriptionPlanModel(transaction.subscription_plan)" >{{moment(transaction.created_at).format('DD MMM YYYY, HH:mm')}}</td>
                          <td @click="openSubscriptionPlanModel(transaction.subscription_plan)" >{{moment(transaction.updated_at).format('DD MMM YYYY, HH:mm')}}</td>
                          <td class="limited-lines" @click="openSubscriptionPlanModel(transaction.subscription_plan)" >{{transaction.notes}}</td>
                        </tr>
                      </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
    <SubscriptionDetailsModel ref="subscriptionDetailsComponent"/>
</div>
</template>
<script>
import router from "../../router";
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions} from "vuex";
import Multiselect from 'vue-multiselect';
import set_user_detail from '../../helper/helper.js';
import string from "../../constant/strings.js"
import moment from "moment";
import SubscriptionDetailsModel from "../model/subscription_plan_details"
import Cookies from 'js-cookie';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
    name:'AddCITY',
    title: string.PAGE_TITLE_PROFILE,
    components: {
      NavBar,
      Footer,
      SideBar,
      Multiselect,
      SubscriptionDetailsModel,
      DatePicker,
    },
    data() {
    return {
      moment,
      status :['Active','Inactive'],
      gender_list:['Male', 'Female'],
      blood_group_list:["A-", "B+", "B-", "AB+", "AB-", "O+", "O-"],
      option_yes_no :['Yes','No'],
      is_client_master_user:'',
      id:'',
      doctor_id:'',
      business_name:'',
      profile_pic: '',
      profile_pic_file: null,
      img_progress: false,
      subscription_plan: {
        plan_name:'',
        purchased_price:'',
        purchased_date:'',
        plan_expire_in:'',
      },
      previous_subscription_plan: {
        plan_name:'',
        purchased_price:'',
        purchased_date:'',
        plan_expire_in:'',
      },
      typeform:{
        doctor_name:'',
        doctor_mobile_number:'',
        identification:'',
        doctor_email:'',
        doctor_address:'',
        doctor_city:'',
        doctor_postal_code:'',
        doctor_emergency_contact_number:'',
        marital_status:'',
        username:'',
        gender:'',
        blood_group:'',
        desc:'',
        selected_department_id:[],
        now: new Date()
      },
      typeform_business:{
        name:'',
        email:'',
        mobile:'',
        business_name:'',
        multi_specialist:'',
        single_doctor:'',
        no_of_department:'',
        no_of_staff:'',
        address:'',
        city:'',
        state:'',
        postal_code:'',
        country:'',
      },
      departments:[],
      department_selected:[],
      is_btn_spinner:false,
      is_btn_spinner_business:false,
      transactions:[],
      login_history:null,
      storedDeviceId:null,
    }
    },
    validations: {
    typeform: {
      doctor_name:{required},
      doctor_mobile_number:{required,numeric},
      identification:{},
      selected_department_id:{required},
      doctor_email:{required},
      doctor_address:{required},
      doctor_city:{required},
      doctor_postal_code:{required},
      doctor_emergency_contact_number:{required},
      marital_status:{required},
      gender:{required},
      birth_date:{required},
      blood_group:{required},
      username:{required},
      desc:{},
    },
    typeform_business:{
      name:{required},
      email:{required},
      mobile:{required,numeric},
      business_name:{required},
      multi_specialist:{required},
      single_doctor:{required},
      no_of_department:{required},
      no_of_staff:{required},
      address:{required},
      city:{required},
      state:{required},
      postal_code:{required},
      country:{required},
    },
  },
  mounted(){    
    this.getAdminDetails()
    this.getDepartment()
    this.getPaymentTransactions()
    this.getLoginHistory()
    this.getDeviceId()
  },
  methods:{    
    ...mapActions("hospital",["getAdminDetailsData","editDoctorDetailsData", "getOPDDepartmentListData", "updateBusinessDetailsData", "getPaymentTransactionsData", "verifyPaymentData", "downloadPaymentTransactionInvoiceData", "loginHistoryAPI", "logoutAdmin", 'deleteProfilePicData']),

    getAdminDetails(){
      var bodyFormData = new FormData();
      bodyFormData.append('is_from_profile', true);
      this.getAdminDetailsData(bodyFormData).then((response) => {
        if (response.response_code == 200) {          
            this.is_client_master_user = response.data.is_client_master_user;
            this.doctor_id = response.data.id;
            this.id = response.data.client_id.id;
            this.subscription_plan = response.subscription_plan;
            this.previous_subscription_plan = response.previous_subscription_plan;
            this.profile_pic = response.data.profile_pic;
            this.typeform= {
              doctor_name:response.data.name,
              doctor_mobile_number:response.data.mobile,
              identification:response.data.identification_number,
              department:'',
              doctor_email : response.data.email,
              doctor_address : response.data.address,
              doctor_city : response.data.doctor_city,
              doctor_postal_code : response.data.doctor_postal_code,
              doctor_emergency_contact_number : response.data.doctor_emergency_contact_number,
              marital_status : response.data.marital_status,
              username : response.data.username,
              gender : response.data.gender,
              birth_date : new Date(response.data.birth_date),
              blood_group : response.data.blood_group,
              status : response.data.status,
              desc : response.data.desc,
            }
            this.typeform_business = {
              name: response.data.client_id.name,
              email: response.data.client_id.email,
              mobile: response.data.client_id.mobile,
              business_name: response.data.client_id.business_name,
              multi_specialist: response.data.client_id.multi_specialist,
              single_doctor: response.data.client_id.single_doctor,
              no_of_department: response.data.client_id.no_of_department,
              no_of_staff: response.data.client_id.no_of_staff,
              address: response.data.client_id.address,
              city: response.data.client_id.city,
              state: response.data.client_id.state,
              postal_code: response.data.client_id.postal_code,
              country: response.data.client_id.country,
            }
            this.business_name= response.data.client_id.business_name;
            if(response.data.department.length > 0) {
                this.department_selected = response.data.department;
            }
        } else if (response.response_code != 104){
            this.$toasted.error(response.message, {duration: 4000,});
        }
      });
    },
    getDepartment(){
        var bodyFormData = new FormData();
        bodyFormData.append('is_from_profile', true);
          this.getOPDDepartmentListData(bodyFormData).then((response) => {
            if (response.response_code == 200) {
                this.departments = response.data;
            }
        });
    },
    editSubmitForm(){
      this.$v.$touch();
      this.typeform.selected_department_id = [];
      if(this.department_selected && this.department_selected.length > 0){
        this.department_selected.forEach((department_obj) => {
              this.typeform.selected_department_id.push(department_obj.id);
        })
      }
      this.typeform.selected_department_id = JSON.stringify(this.typeform.selected_department_id);

      if (!this.$v.typeform.$invalid) {
        this.is_btn_spinner=true;
        var bodyFormData = new FormData();
        bodyFormData.append('doctor_id', this.doctor_id);
        bodyFormData.append('doctor_name', this.typeform.doctor_name);
        bodyFormData.append('doctor_mobile_number', this.typeform.doctor_mobile_number);
        bodyFormData.append('doctor_identification', this.typeform.identification);
        bodyFormData.append('doctor_email', this.typeform.doctor_email);
        bodyFormData.append('doctor_address', this.typeform.doctor_address);
        bodyFormData.append('doctor_city', this.typeform.doctor_city);
        bodyFormData.append('doctor_postal_code', this.typeform.doctor_postal_code);
        bodyFormData.append('doctor_emergency_contact_number', this.typeform.doctor_emergency_contact_number);
        bodyFormData.append('marital_status', this.typeform.marital_status);
        bodyFormData.append('gender', this.typeform.gender);
        bodyFormData.append('birth_date', moment(this.typeform.birth_date).format('YYYY-MM-DD'));
        bodyFormData.append('blood_group', this.typeform.blood_group);
        bodyFormData.append('username', this.typeform.username);
        bodyFormData.append('department', this.typeform.selected_department_id);
        bodyFormData.append('profile_pic', this.profile_pic_file);
        bodyFormData.append('desc', this.typeform.desc);

        this.editDoctorDetailsData(bodyFormData).then((response) => {
            this.is_btn_spinner=false;
          if (response.response_code == 200) {
            localStorage.setItem('user_details', JSON.stringify(response.data));
            this.$toasted.success(response.message, {duration: 2000,});
            this.$router.push({ name: 'home' });
          }else{
            this.$toasted.error(response.message, {duration: 2000,});
          }
        });
      }
    },

    editBusinessDetails() {
      this.$v.$touch();
      if (!this.$v.typeform_business.$invalid) {
        this.is_btn_spinner_business=true;
        var bodyFormData = new FormData();
        bodyFormData.append('client_id', this.id);
        bodyFormData.append('name', this.typeform_business.name);
        bodyFormData.append('email', this.typeform_business.email);
        bodyFormData.append('mobile', this.typeform_business.mobile);
        bodyFormData.append('business_name', this.typeform_business.business_name);
        bodyFormData.append('multi_specialist', this.typeform_business.multi_specialist);
        bodyFormData.append('single_doctor', this.typeform_business.single_doctor);
        bodyFormData.append('no_of_department', this.typeform_business.no_of_department);
        bodyFormData.append('no_of_staff', this.typeform_business.no_of_staff);
        bodyFormData.append('address', this.typeform_business.address);
        bodyFormData.append('city', this.typeform_business.city);
        bodyFormData.append('state', this.typeform_business.state);
        bodyFormData.append('postal_code', this.typeform_business.postal_code);
        bodyFormData.append('country', this.typeform_business.country);

        this.updateBusinessDetailsData(bodyFormData).then((response) => {
            this.is_btn_spinner_business=false;
          if (response.response_code == 200) {
            this.$toasted.success(response.message, {duration: 2000,});
            //this.$router.push({ name: 'doctor' });
          }else{
            this.$toasted.error(response.message, {duration: 2000,});
          }
        });
      }
    },

    getPaymentTransactions() {
      var bodyFormData = new FormData();
      this.getPaymentTransactionsData(bodyFormData).then((response) => {
          if (response.response_code == 200) {
            this.transactions = response.data
            this.openSubscriptionPlanModel(this.transactions[0])
          }else{
            this.$toasted.error(response.message, {duration: 2000,});
          }
        });
    },

    getLoginHistory() {
      var bodyFormData = new FormData();
      this.loginHistoryAPI(bodyFormData).then((response) => {
          if (response.response_code == 200) {
            this.login_history = response.data
          }else{

          }
        });
    },

    verifyTransactionPayment(event, order_id) {
      event.preventDefault();
      var bodyFormData = new FormData();
      bodyFormData.append("order_id", order_id);
      this.verifyPaymentData(bodyFormData).then((response) => {
          if (response.response_code == 200) {
            this.$toasted.success(response.message, {duration: 2000,});
            this.getPaymentTransactions();
          }else{
            this.$toasted.error(response.message, {duration: 2000,});
          }
        });
    },

    downloadPaymentTransactionInvoice(event, order_id) {
      event.preventDefault();
      var bodyFormData = new FormData();
      bodyFormData.append("order_id", order_id);
      this.downloadPaymentTransactionInvoiceData(bodyFormData).then((response) => {
          if (response.response_code == 200) {
            this.$toasted.success(response.message, {duration: 2000,});
          }else{
            this.$toasted.error(response.message, {duration: 2000,});
          }
        });
    },

    openSubscriptionPlanModel(subscription_plan) {
        if(subscription_plan == null || subscription_plan == undefined){
            return
        } else {
            this.$refs.subscriptionDetailsComponent.clearData();
            this.$refs.subscriptionDetailsComponent.receiveData(subscription_plan);
            this.$refs.subscriptionDetailsComponent.show();
        }
    },

    logout(device_id) {
      var bodyFormData = new FormData();
      bodyFormData.append("device_id", device_id);
      bodyFormData.append("logout_by_device_id", true);
      this.logoutAdmin(bodyFormData).then((response) => {
          if (response.response_code == 200) {
            this.$toasted.success(response.message, {duration: 2000,});
                for (let i = 0; i <= this.login_history.length;i++){
                        if(device_id == this.login_history[i].device_id) {
                            this.login_history.splice(i, 1);
                            break;
                        }
                    }
          }else{
            this.$toasted.error(response.message, {duration: 2000,});
          }
        });
    },
    getDeviceId() {
      this.storedDeviceId = Cookies.get('device_id');
    },
     disableDatesAfterMax(date) {
      return date > new Date();
    },
     openImagePicker() {
        if(!this.profile_pic.startsWith('http')){
            this.$refs.image.click();
        }
    },
    handleFileInput(e) {
      const file = e.target.files[0];
      this.profile_pic = URL.createObjectURL(file);
      this.profile_pic_file = file;
    },
    deleteProfilePic() {
      if(this.profile_pic.startsWith('http')){
          this.img_progress = true;
         var bodyFormData = new FormData();
         if(this.doctor_id){
            bodyFormData.append('doctor_id', this.doctor_id);
         }
          this.deleteProfilePicData(bodyFormData).then((response) => {
            this.img_progress = false;
            if (response.response_code == 200) {
                  this.profile_pic = ''
                  this.profile_pic_file = null;
                  this.$toasted.success(response.message, {duration: 2000,});
            } else{
                  this.$toasted.error(response.message, {duration: 2000,});
            }
          });
        } else {
             this.profile_pic = ''
             this.profile_pic_file = null;
       }
    },
    handleImageError(event) {
      // If there's an error loading the image, set a default image
      event.target.src = '../../assets/dummy-profile.jpg';  // Use your dummy profile image path here
    },
    profilePic() {
      return this.profile_pic || '../../assets/dummy-profile.jpg'; // If profile_pic is empty, fallback to default
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>